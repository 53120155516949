import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AuthProvider } from 'react-check-auth'
import Header from './Components/Header'
import PrivateRoute from './Components/PrivateRoute'

import LoginPage from './LoginPage'
// import DashboardPage from './DashboardPage'
import UsersPage from './Admin/UsersPage'
import ThankYouPage from './Components/ThankYouPage/ThankYouPage'
import UserDetailsPage from './Admin/UserDetailsPage'
import DashboardPage from './DashboardPage'

class App extends Component {
  constructor(props) {
    super(props)

    this.authProvider = null
  }

  refreshAuth = user => {
    if (this.authProvider) {
      if (user) {
        this.authProvider.fetchSuccess(user)
      } else {
        this.authProvider.refreshAuth()
      }
    }
  }

  render() {
    return (
      <Router>
        <AuthProvider
          authUrl={'/api/users/current'}
          ref={provider => (this.authProvider = provider)}>
          <Header />
          <Switch>
            <PrivateRoute exact path='/' component={DashboardPage} />
            <PrivateRoute
              path='/admin/users/:id'
              component={UserDetailsPage}
              admin
            />
            <PrivateRoute path='/admin/users' component={UsersPage} admin />
            <Route
              exact
              path='/login'
              render={props => (
                <LoginPage {...props} refreshAuth={this.refreshAuth} />
              )}
            />
            <Route path='/thankyou' component={ThankYouPage} />
          </Switch>
        </AuthProvider>
      </Router>
    )
  }
}

export default App
