import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios, { CancelToken } from 'axios'
import memoize from 'memoize-one'

import SpiralPlot from '../../Components/SpiralPlot'
import api from '../../api'
import strings from '../../strings'
import BlockLoader from '../../Components/BlockLoader'
import TopFlop from './TopFlop'
import ModeSelector from './ModeSelector'
import styles from './styles.module.css'

const TOP_FLOP_NUM = 20

class Behavioral extends Component {
  state = {
    isLoading: false,
    data: [],
    mode: 'all'
  }

  constructor(props) {
    super(props)

    this.cancel = null
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillUnmount() {
    if (this.cancel) {
      this.cancel()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.country !== prevProps.country ||
      this.props.brand !== prevProps.brand ||
      this.props.model !== prevProps.model ||
      this.props.level !== prevProps.level ||
      this.props.population !== prevProps.population ||
      this.props.kle !== prevProps.kle
    ) {
      this.fetchData()
    }
  }

  cancelToken = () => {
    const that = this
    return new CancelToken(function executor(c) {
      that.cancel = c
    })
  }

  filter = memoize((data, mode) => {
    switch (mode) {
      case 'top':
        return data.slice(-TOP_FLOP_NUM)
      case 'flop':
        return data.slice(0, TOP_FLOP_NUM)
      default:
        return data
    }
  })

  async fetchData() {
    if (this.props.brand) {
      if (!this.state.data.length) {
        this.setState({ isLoading: true })
      } else {
        this.props.onLoadingChanged(true)
      }
      try {
        const response = await api.getUplift(
          this.props.country,
          this.cancelToken(),
          this.props.brand,
          this.props.model,
          this.props.level,
          this.props.population,
          false
        )
        const data = response.data.data
        data.reverse()
        this.setState({ isLoading: false, data: data })
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err)
        }
      }
      this.cancel = null
    } else {
      this.setState({ data: [] })
    }
    this.props.onLoadingChanged(false)
  }

  render() {
    const { brand, population, displayModes } = this.props
    const { mode, isLoading, data } = this.state
    let message = brand ? null : strings.dashboard.pleaseChooseBrand
    const tooltipMessage =
      population === 'CountryPop'
        ? strings.dashboard.behavioralTraitsTooltipUsPopulation
        : strings.dashboard.behavioralTraitsTooltipAllBrands

    const filtered = this.filter(data, mode)

    return (
      <BlockLoader
        loading={isLoading}
        message={message}
        className={'behavioral'}>
        {mode === 'all' ? (
          <SpiralPlot
            className='spiral'
            data={filtered}
            tooltipMessage={tooltipMessage}
          />
        ) : (
          <TopFlop data={filtered} tooltipMessage={tooltipMessage} />
        )}
        {displayModes && population === 'CountryPop' && (
          <ModeSelector
            mode={mode}
            onChange={this.onModeChange}
            className={styles.selector}
          />
        )}
      </BlockLoader>
    )
  }

  onModeChange = mode => {
    this.setState({ mode })
  }
}

Behavioral.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  level: PropTypes.string,
  population: PropTypes.string,
  onLoadingChanged: PropTypes.func
}

Behavioral.defaultProps = {
  country: null,
  brand: null,
  model: null,
  level: null,
  population: null
}

export default Behavioral
