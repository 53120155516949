import React, { PureComponent } from 'react'
import Img from 'react-image'
import PropTypes from 'prop-types'

import { toSnakeCase } from '../../utils'
import car from './car.png'

const baseUrl = process.env.REACT_APP_THUMBS_BASE_BUCKET

function carPlaceholder(props) {
  return <img src={car} alt='' {...props} />
}

class Thumbnail extends PureComponent {
  static carImage(brand, model) {
    brand = brand.toLowerCase()

    let brandSlug = toSnakeCase(brand)

    let url = baseUrl

    if (model && !model.includes('GLOBAL')) {
      model = model.toLowerCase()
      const modelSlug = model.replace(/\s/g, '_')
      url += `/cars/${brandSlug}__${modelSlug}.png`
    } else {
      url = `https://storage.googleapis.com/automotive_us_dash/thumbs-experimental/BRANDS/${brandSlug}.png`
    }

    return url
  }

  render() {
    let { country, brand, model, ...rest } = this.props

    if (country) {
      return (
        <Img
          src={`https://www.countryflags.io/${country.toLowerCase()}/shiny/48.png`}
        />
      )
    }

    let url = Thumbnail.carImage(brand, model)
    let alt = brand

    if (model) {
      alt += ' ' + model
    }

    return (
      <Img
        src={url}
        alt={alt}
        loader={carPlaceholder(rest)}
        unloader={carPlaceholder(rest)}
        {...rest}
      />
    )
  }
}

Thumbnail.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string
}

export default Thumbnail
