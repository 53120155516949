import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Item from './Item'
import styles from './styles.module.css'

class CarGrid extends PureComponent {
  render() {
    const { brand, model, data } = this.props

    return (
      <div className={styles.grid}>
        {data.map((item, i) => (
          <Item
            data={item}
            index={i}
            key={item.brand + '-' + item.model}
            brand={brand}
            model={model}
          />
        ))}
      </div>
    )
  }
}

CarGrid.propTypes = {
  brand: PropTypes.string,
  model: PropTypes.string,
  data: PropTypes.array.isRequired
}

export default CarGrid
