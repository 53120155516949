import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Grid } from 'semantic-ui-react'
import Picker from './Picker'
import Thumbnail from '../../Components/Thumbnail'
import api from '../../api'
import strings from '../../strings'
import styles from './styles.module.css'

class Settings extends Component {
  state = {
    countries: [],
    brands: [],
    models: []
  }

  componentDidMount() {
    this.fetchCountries()
    const { country, brand } = this.props
    if (country) {
      this.fetchBrands(country)
    }
    if (country && brand) {
      this.fetchModels(country, brand)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { country, brand } = this.props

    if (country !== prevProps.country && country) {
      this.fetchBrands(country)
    }

    if (brand !== prevProps.brand && brand) {
      this.fetchModels(country, brand)
    }
  }

  async fetchCountries() {
    const countries = await api.getCountries()
    this.setState({ countries: countries.data.data })
  }

  async fetchBrands(country) {
    this.setState({ brands: [] })
    if (country) {
      const brands = await api.getBrands(country)
      this.setState({ brands: brands.data.data })
    }
  }

  async fetchModels(country, brand) {
    this.setState({ models: [] })
    if (country && brand) {
      const models = await api.getModels(country, brand)
      this.setState({ models: models.data.data })
    }
  }

  onCountrySelected = country => {
    document.title = `Weborama | Automotive ${country}`
    this.props.onCountryChanged(country)
  }

  onBrandSelected = brand => {
    this.props.onBrandChanged(brand)
  }

  onModelSelected = model => {
    this.props.onModelChanged(model)
  }

  render() {
    const { country, brand, model } = this.props
    const { countries, brands, models } = this.state

    return (
      <Grid>
        <Grid.Column computer={2} tablet={8} mobile={12}>
          <Picker
            id='country'
            data={countries}
            value={country}
            placeholder={strings.dashboard.chooseBrand}
            onChange={this.onCountrySelected}
            loading={!countries.length}
            disabled={!countries.length}
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={8} mobile={4}>
          <div className={styles.imgWrapper}>
            {country && (
              <Thumbnail country={country} className={styles.thumb} />
            )}
          </div>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={12}>
          <Picker
            id='brand'
            data={brands}
            value={brand}
            placeholder={strings.dashboard.chooseBrand}
            onChange={this.onBrandSelected}
            loading={!!country && !brands.length}
            disabled={!country || !brands.length}
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={8} mobile={4}>
          <div className={styles.imgWrapper}>
            {country && brand && (
              <Thumbnail brand={brand} className={styles.thumb} />
            )}
          </div>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={12}>
          <Picker
            id='model'
            data={models.filter(d => !d.includes('GLOBAL'))}
            value={model}
            placeholder={strings.dashboard.chooseModel}
            onChange={this.onModelSelected}
            loading={!!country && !!brand && !models.length}
            disabled={!country || !brand || !models.length}
            // key={'brand-' + (brand || 'key')}
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={8} mobile={4}>
          <div className={styles.imgWrapper}>
            {country && brand && model && (
              <Thumbnail brand={brand} model={model} className={styles.thumb} />
            )}
          </div>
        </Grid.Column>
      </Grid>
    )
  }
}

Settings.propTypes = {
  onCountryChanged: PropTypes.func.isRequired,
  onBrandChanged: PropTypes.func.isRequired,
  onModelChanged: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string
}

export default Settings
