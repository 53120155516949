import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'

import strings from '../../../strings'

class ModeSelector extends PureComponent {
  render() {
    const { mode, className } = this.props

    return (
      <div className={className}>
        <Button.Group size='mini'>
          <Button active={mode === 'all'} onClick={this.onClick('all')}>
            {strings.dashboard.all}
          </Button>
          <Button active={mode === 'top'} onClick={this.onClick('top')}>
            {strings.dashboard.top}
          </Button>
          <Button active={mode === 'flop'} onClick={this.onClick('flop')}>
            {strings.dashboard.bottom}
          </Button>
        </Button.Group>
      </div>
    )
  }

  onClick = mode => {
    return e => {
      this.props.onChange(mode)
    }
  }
}

ModeSelector.propTypes = {
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ModeSelector
