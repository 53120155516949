import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import numeral from 'numeral'

import styles from './styles.module.css'
import strings from '../../strings'

class UpliftToolTip extends PureComponent {
  render() {
    const { messageFormat, uplift, modalite } = this.props

    return (
      <div
        className={classNames(
          'ui basic popup transition visible',
          styles.tooltip
        )}>
        <div className={classNames('content', styles.content)}>
          {strings.formatString(
            messageFormat,
            <strong>{numeral(uplift).format('0.0')}</strong>,
            <strong>{modalite}</strong>,
            <strong>{window.country}</strong>
          )}
        </div>
      </div>
    )
  }
}

UpliftToolTip.propTypes = {
  messageFormat: PropTypes.string.isRequired,
  uplift: PropTypes.number.isRequired,
  modalite: PropTypes.string.isRequired
}

export default UpliftToolTip
