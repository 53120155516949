import React from 'react'
import { Container, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from 'react-check-auth'
import PropTypes from 'prop-types'

import auth from '../../auth'

const PrivateRoute = ({ component: Component, admin, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <AuthConsumer>
        {({ userInfo, isLoading, error }) => {
          if (userInfo && (!admin || auth.isAdmin(userInfo))) {
            return <Component {...props} />
          }

          if (isLoading || !error) {
            return (
              <Container>
                <Segment style={{ height: '700px' }}>
                  <Dimmer active>
                    <Loader />
                  </Dimmer>

                  <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
              </Container>
            )
          }

          return <Redirect to='/login' />
        }}
      </AuthConsumer>
    )}
  />
)

PrivateRoute.propTypes = {
  admin: PropTypes.bool
}

export default PrivateRoute
