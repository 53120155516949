import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'

import UsersListItem from './UsersListItem'
import strings from '../../../strings'

class UsersList extends PureComponent {
  render() {
    const { users, sortColumn, sortDirection, onSortClick } = this.props

    return (
      <Table sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'id' ? sortDirection : null}
              onClick={onSortClick('id')}>
              {strings.admin.users.id}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'lastname' ? sortDirection : null}
              onClick={onSortClick('lastname')}>
              {strings.admin.users.lastName}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'firstname' ? sortDirection : null}
              onClick={onSortClick('firstname')}>
              {strings.admin.users.firstName}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'email' ? sortDirection : null}
              onClick={onSortClick('email')}>
              {strings.admin.users.email}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'company' ? sortDirection : null}
              onClick={onSortClick('company')}>
              {strings.admin.users.company}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'active' ? sortDirection : null}
              onClick={onSortClick('active')}>
              {strings.admin.users.active}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'admin' ? sortDirection : null}
              onClick={onSortClick('admin')}>
              {strings.admin.users.admin}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map(user => (
            <UsersListItem user={user} key={`user:${user.id}`} />
          ))}
        </Table.Body>
      </Table>
    )
  }
}

UsersList.propTypes = {
  users: PropTypes.array.isRequired,
  onSortClick: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string
}

export default UsersList
