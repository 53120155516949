import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'
import numeral from 'numeral'

import { capitalize } from '../../../utils'

import CarContext from '../../CarContext'
import Thumbnail from '../../Thumbnail'
import styles from '../styles.module.css'
import strings from '../../../strings'

class Item extends PureComponent {
  render() {
    const { data, index, brand, model } = this.props
    const { score, volume } = data
    const itemBrand = data.brand
    const itemModel = data.model

    const referenceCar = brand + ' ' + model
    const itemCar = itemBrand + ' ' + itemModel

    const tooltip = score
      ? strings.formatString(
          strings.dashboard.proximityTooltip,
          <strong>{capitalize(referenceCar)}</strong>,
          <strong>{capitalize(itemCar)}</strong>,
          <strong>{score}</strong>
        )
      : strings.formatString(
          strings.dashboard.marketVisionTooltip,
          <strong>{capitalize(referenceCar)}</strong>,
          <strong>{capitalize(itemCar)}</strong>,
          <strong>{numeral(index + 1).format('0o')}</strong>
        )

    const clickHandler = () => this.context.configureCar(itemBrand, itemModel)

    return (
      <Popup
        trigger={
          <div className={styles.item} onClick={clickHandler}>
            <div className={styles.imgWrapper}>
              <div className={styles.imgCenter}>
                <Thumbnail brand={itemBrand} model={itemModel} />
              </div>
              <div className={styles.data}>
                <p className={styles.car}>{capitalize(itemCar)}</p>
                {score && <p className={styles.score}>{score}</p>}
              </div>
              {volume && (
                <div className={styles.volume}>{numeral(volume).format()}</div>
              )}
            </div>
          </div>
        }
        basic
        hoverable
        content={tooltip}
      />
    )
  }
}

Item.propTypes = {
  brand: PropTypes.string,
  model: PropTypes.string,
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
}

Item.contextType = CarContext

export default Item
