import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BlockLoader from '../BlockLoader'
import CarGrid from '../CarGrid'
import strings from '../../strings'
import styles from './styles.module.css'

class TopTen extends Component {
  state = {
    isLoading: false,
    models: []
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.country !== prevProps.country ||
      this.props.brand !== prevProps.brand ||
      this.props.model !== prevProps.model
    ) {
      this.fetchData()
    }
  }

  async fetchData() {
    if (this.props.brand && this.props.model) {
      if (this.state.models.length === 0) {
        this.setState({ isLoading: true })
      } else {
        this.props.onLoadingChanged(true)
      }
      const response = await this.props.dataFetcher(
        this.props.country,
        this.props.brand,
        this.props.model
      )
      this.setState({ isLoading: false, models: response.data.data })
    } else {
      this.setState({ models: [] })
    }
    this.props.onLoadingChanged(false)
  }

  render() {
    const { brand, model } = this.props
    let message = null
    if (brand) {
      if (!model) {
        message = strings.dashboard.pleaseChooseModel
      }
    } else {
      message = strings.dashboard.pleaseChooseBrandAndModel
    }

    return (
      <BlockLoader
        loading={this.state.isLoading}
        message={message}
        className={styles.topTen}>
        <CarGrid data={this.state.models} brand={brand} model={model} />
      </BlockLoader>
    )
  }
}

TopTen.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  dataFetcher: PropTypes.func.isRequired,
  onLoadingChanged: PropTypes.func
}

TopTen.defaultProps = {
  country: null,
  brand: null,
  model: null
}

export default TopTen
