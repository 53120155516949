import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Grid,
  Input,
  Button,
  Form,
  List,
  Header,
  Image,
  Container,
  Card
} from 'semantic-ui-react'

import PageBlock from '../Components/PageBlock'
import strings from '../strings'
import auth from '../auth'
import styles from './styles.module.css'

import image5 from './image5.png'
import image6 from './image6.png'
import image7 from './image7.png'

class LoginPage extends PureComponent {
  state = {
    // ORIGINAL FORM FIELDS
    isLoading: false,
    error: null,
    valid: false,
    username: null,
    password: null,

    // NEW FORM FIELDS
    isLoadingSignUp: false,
    errorSignUp: null,
    newUserValid: false,
    newfirstname: null,
    newlastname: null,
    newcompany: null,
    newposition: null,
    newemail: null,
    newpassword: null
  }

  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const {
          newfirstname,
          newlastname,
          newcompany,
          newposition,
          newemail,
          newpassword
        } = this.state
        const newUserValid = this.validate(
          newfirstname,
          newlastname,
          newcompany,
          newposition,
          newemail,
          newpassword
        )
        this.setState({ newUserValid })
      }
    )
  }

  usernameChanged = e => {
    const username = e.target.value
    const valid = this.validate(username, this.state.password)
    this.setState({ username, valid })
  }

  passwordChanged = e => {
    const password = e.target.value
    const valid = this.validate(this.state.username, password)
    this.setState({ password, valid })
  }

  login = e => {
    this.doLogin()
    e.preventDefault()
    return false
  }

  signup = e => {
    this.doSignup()
    e.preventDefault()
    return false
  }

  validate = (...args) => {
    return args.every(el => el)
  }

  async doLogin() {
    try {
      this.setState({ isLoading: true, error: null })
      const user = await auth.login(this.state.username, this.state.password)
      this.setState({ isLoading: false })
      this.props.refreshAuth(user)
      this.props.history.replace('/')
    } catch (e) {
      this.setState({ error: strings.login.error, isLoading: false })
    }
  }

  async doSignup() {
    try {
      this.setState({ isLoadingSignUp: true, errorSignUp: null })
      this.setState({ isLoadingSignUp: false })
      this.props.history.replace('/thankyou')
    } catch (error) {
      this.setState({
        errorSignUp: strings.signup.error,
        isLoadingSignUp: false
      })
    }
  }

  render() {
    const disableButton = this.state.isLoading || !this.state.valid
    const newDisableButton = this.state.isLoading || !this.state.newUserValid
    const items = [
      {
        header: 'Propietary and unique semantic AI approach.',
        image: image5,
        description:
          'Leverage agile frameworks to provide a robust synopsis for high level overviews.',
      },
      {
        header: 'Powerful vertical segmentation.',
        image: image6,
        description:
          'Bring to the table win-win survival strategies to ensure proactive domination.',
      },
      {
        header: 'Unique Consumer Journey Approach.',
        image: image7,
        description:
          'Capitalise on low hanging fruit to identify a ballpark value added activity to beta test.',
      },
    ]

    const CardExampleGroupProps = () => <Card.Group cent items={items} />
    return (
      <PageBlock className={styles.content}>
        <Grid>
          <Grid.Column width={12}>
            <Header>Weborama presents its unique Automotive Dataset.</Header>
            <List bulleted>
              <List.Item>
                We help our partners and client to choose the right segments.
              </List.Item>
              <List.Item>Solely oriented to Auto Market</List.Item>
              <List.Item>Focused on Automotive Industry</List.Item>
            </List>

            <Card.Group items={items} />
          </Grid.Column>

          <Grid.Column width={4}>
            <div className={styles.login}>
              <h2>{strings.login.login}</h2>
              <Form>
                <Form.Field>
                  <label htmlFor='username'>{strings.login.email}</label>
                  <Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    id='username'
                    name='username'
                    type='email'
                    autoComplete='username'
                    onChange={this.usernameChanged}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor='password'>{strings.login.password}</label>
                  <Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='password'
                    onChange={this.passwordChanged}
                  />
                </Form.Field>
                <Button
                  color='red'
                  className='webo'
                  fluid
                  disabled={disableButton}
                  loading={this.state.isLoading}
                  onClick={this.login}>
                  {strings.login.submit}
                </Button>
                {this.state.error && (
                  <div className={styles.error}>{this.state.error}</div>
                )}
              </Form>
            </div>

            <div className={styles.login}>
              <h2>{strings.signup.signup}</h2>
              <Form>
                <Form.Field>
                  <label htmlFor='newfirstname'>
                    {strings.signup.newfirstname}
                  </label>
                  <Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    id='newfirstname'
                    name='newfirstname'
                    type='email'
                    autoComplete='newfirstname'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor='newlastname'>
                    {strings.signup.newlastname}
                  </label>
                  <Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    id='newlastname'
                    name='newlastname'
                    type='email'
                    autoComplete='newlastname'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor='newcompany'>
                    {strings.signup.newcompany}
                  </label>
                  <Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    id='newcompany'
                    name='newcompany'
                    type='email'
                    autoComplete='newcompany'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor='newposition'>
                    {strings.signup.newposition}
                  </label>
                  <Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    id='newposition'
                    name='newposition'
                    type='email'
                    autoComplete='newposition'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor='newemail'>{strings.signup.newemail}</label>
                  <Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    id='newemail'
                    name='newemail'
                    type='email'
                    autoComplete='newemail'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor='newpassword'>
                    {strings.signup.newpassword}
                  </label>
                  <Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    id='newpassword'
                    name='newpassword'
                    type='password'
                    autoComplete='newpassword'
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Button
                  color='red'
                  className='webo'
                  fluid
                  disabled={newDisableButton}
                  loading={this.state.isLoadingSignUp}
                  onClick={this.signup}>
                  {strings.signup.submit}
                </Button>
                {this.state.errorSignUp && (
                  <div className={styles.error}>{this.state.errorSignUp}</div>
                )}
              </Form>
            </div>
          </Grid.Column>
        </Grid>
      </PageBlock>
    )
  }
}

export default withRouter(LoginPage)
