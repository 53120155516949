import * as d3 from 'd3'
import { makeColorScale, colorMin, globalFont } from '../../utils'
import numeral from 'numeral'

import strings from '../../strings'

/*
 * https://bl.ocks.org/arpitnarechania/027e163073864ef2ac4ceb5c2c0bf616
 * https://bl.ocks.org/larsenmtl/9dce3ba275ccd8978868edf8cffaf8c8
 */

const outerMargin = 80
const innerMargin = 4
const labelMargin = 10
const innerRadius = 10
const numSpirals = 4
const animationDurationIn = 400
const animationDurationOut = 500

function spiralPlot(
  rootElement,
  { width, height },
  data,
  tooltipMessage,
  animate = false
) {
  const N = data.length
  const start = 0
  const end = N - 1

  // Maximum radius
  const r = d3.min([width, height]) / 2 - outerMargin

  const theta = function(i) {
    return (numSpirals * 2 * Math.PI * i) / end - Math.PI / 4
  }

  const radius = d3
    .scaleLinear()
    .domain([start, end])
    .range([innerRadius, r])

  // Color Scale
  const color = makeColorScale()

  const root = d3.select(rootElement)
  if (!N) {
    removeAll(root)
    return
  }
  if (!animate) {
    removeAll(root)
  }

  const svg = createSvg(root, width, height)

  const path = createSpiralPath(svg, start, end, theta, radius)
  const node = path.node()

  const spiralLength = node.getTotalLength()
  const barWidth = spiralLength / N - 1

  const ordinalScale = d3
    .scaleBand()
    .domain(data.map(el => el.modalite))
    .range([0, spiralLength])

  let prevPos = null
  data.forEach(pointPosition)

  function pointPosition(d, i) {
    const linePer = ordinalScale(d.modalite)
    const posOnLine = node.getPointAtLength(linePer)
    const angleOnLine = prevPos || posOnLine
    prevPos = posOnLine

    d.linePer = linePer // % distance are on the spiral
    d.x = posOnLine.x // x postion on the spiral
    d.y = posOnLine.y // y position on the spiral
    d.index = i

    d.a = (Math.atan2(angleOnLine.y, angleOnLine.x) * 180) / Math.PI - 90 //angle at the spiral position
  }

  const pointColor = d => color(d.uplift)

  const tooltip = setupToolTip(root)

  /*
   * We don't want to display data labels on inner parts of the spiral, only the outer ring
   * So we need to find which data index corresponds to the end of the outer ring
   */
  const indexMinWithLabel = computeIndexMinWithLabel(
    data,
    path,
    radius,
    ordinalScale
  )
  const innerIndexMax = indexMinWithLabel ? indexMinWithLabel - 1 : 0

  const innerHeadRoom = r / numSpirals - innerMargin
  const minBarHeight = innerHeadRoom / 3
  const maxBarHeight = innerHeadRoom * 4
  // yScale for the bar height
  const yScale = d3
    .scaleLinear()
    .domain([0, data[innerIndexMax].uplift])
    .range([0, innerHeadRoom])

  const scaledUplift = d => {
    const height = yScale(d.uplift)
    if (height < minBarHeight) {
      return minBarHeight
    }
    if (height > maxBarHeight) {
      return maxBarHeight
    }
    return height
  }

  createBars(
    svg,
    tooltip,
    data,
    barWidth,
    scaledUplift,
    pointColor,
    tooltipMessage,
    animate
  )

  createLabels(
    svg,
    tooltip,
    data,
    indexMinWithLabel,
    pointColor,
    scaledUplift,
    tooltipMessage,
    animate
  )
}

function removeAll(root) {
  root.select('svg').remove()
}

function createSvg(root, width, height) {
  let svg = root.select('svg')
  if (svg.size() === 0) {
    svg = root
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
  } else {
    svg = svg.select('g')
  }

  return svg
}

function createSpiralPath(svg, start, end, theta, radius) {
  let path = svg.select('path')

  if (path.size() === 0) {
    const points = d3.range(start, end, 1)

    const spiral = d3
      .radialLine()
      .curve(d3.curveCardinal)
      .angle(theta)
      .radius(radius)

    path = svg
      .append('path')
      .datum(points)
      .attr('id', 'spiral')
      .attr('d', spiral)
      .style('fill', 'none')
      .style('stroke', colorMin)
  }

  return path
}

function createBars(
  svg,
  tooltip,
  data,
  barWidth,
  scaledUplift,
  pointColor,
  tooltipMessage,
  animate
) {
  const oldBars = svg.selectAll('rect')
  const oldLen = oldBars.size()

  function doBars(dat, idx) {
    if (oldLen > 0 && idx < oldLen - 1) {
      return
    }

    const bars = svg
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('id', (d, i) => 'datum-' + i)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('width', barWidth)
      .attr('height', animate ? 0 : scaledUplift)
      .style('fill', pointColor)
      .style('stroke', 'none')
      .attr('transform', d => 'rotate(' + d.a + ',' + d.x + ',' + d.y + ')')

    setupMouseOver(svg, tooltip, pointColor, 'rect', tooltipMessage)

    if (animate) {
      bars
        .transition()
        .duration(animationDurationIn)
        .delay((d, i) => 2 * i)
        .attr('height', scaledUplift)
    }
  }

  if (animate && oldLen > 0) {
    oldBars
      .transition()
      .duration(animationDurationOut)
      .attr('height', 0)
      .on('interrupt end', doBars)
      .remove()
  } else {
    doBars()
  }
}

function createLabels(
  svg,
  tooltip,
  data,
  indexMinWithLabel,
  pointColor,
  scaledUplift,
  tooltipMessage,
  animate
) {
  const oldText = svg.selectAll('text')
  const oldLen = oldText.size()

  const labelAnchor = d => {
    let angle = d.a % 360
    return angle > 0 || angle < -180 ? 'end' : 'start'
  }

  function doLabels(dat, idx) {
    if (oldLen > 0 && idx < oldLen - 1) {
      return
    }

    const text = svg
      .selectAll('text')
      .data(data)
      .enter()
      .append('text')
      .attr('dx', d => {
        const delta = scaledUplift(d) + labelMargin
        return labelAnchor(d) === 'start' ? delta : -1 * delta
      })
      .attr('dy', d => (labelAnchor(d) === 'start' ? 0 : 5))
      .style('text-anchor', labelAnchor)
      .style('font', '10px ' + globalFont)
      .filter((d, i) => i >= indexMinWithLabel)
      .text(d => d.modalite)
      .style('fill', 'grey')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('transform', function(d) {
        let angle = labelAnchor(d) === 'start' ? d.a + 90 : d.a - 90
        return 'rotate(' + angle + ',' + d.x + ',' + d.y + ')' // rotate the bar
      })
      .attr('id', d => 'label-' + d.index)
      .style('opacity', animate ? 0 : 1)

    setupMouseOver(svg, tooltip, pointColor, 'text', tooltipMessage)

    if (animate) {
      text
        .transition()
        .duration(animationDurationIn)
        .delay((d, i) => animationDurationIn / 2 + 2 * d.index)
        .style('opacity', 1)
    }
  }

  if (animate && oldLen > 0) {
    oldText
      .transition()
      .duration(animationDurationOut)
      .style('opacity', 0)
      .on('interrupt end', doLabels)
      .remove()
  } else {
    doLabels()
  }
}

function computeIndexMinWithLabel(data, path, radius, ordinalScale) {
  const N = data.length
  // This is the radius at the end of the outer ring
  const limitRadius = radius(((N - 1) * (numSpirals - 1)) / numSpirals)
  // Let's square it once for all to avoid computing square roots later
  const limitRadius2 = limitRadius * limitRadius

  // Now binary search the data point that corresponds to this radius
  let dichoMin = 0
  let dichoMax = N - 1
  const pathNode = path.node()
  while (dichoMax - dichoMin > 1) {
    const middle = Math.ceil((dichoMax + dichoMin) / 2)
    const d = data[middle]
    const len = ordinalScale(d.modalite) // Linear position of the point on the spiral
    const point = pathNode.getPointAtLength(len) // Corresponding point in cartesian coordinates
    const middleRadius2 = point.x * point.x + point.y * point.y // Radius^2 at this point
    if (middleRadius2 <= limitRadius2) {
      dichoMin = middle
    } else {
      dichoMax = middle
    }
  }

  return dichoMax
}

function setupToolTip(root) {
  let tooltip = root.select('#tooltip')

  if (tooltip.size() === 0) {
    tooltip = root
      .append('div')
      .attr('class', 'ui basic popup transition visible')
      .attr('id', 'tooltip')
      .style('display', 'none')
      .style('opacity', 0)

    tooltip.append('div').attr('class', 'content')
  }

  return tooltip
}

function setupMouseOver(svg, tooltip, pointColor, elemType, tooltipMessage) {
  function elementsForData(target, elementType, d, i) {
    let rect
    let text
    if (elementType === 'rect') {
      rect = d3.select(target)
      text = svg.select('#label-' + i)
    } else {
      rect = svg.select('#datum-' + d.index)
      text = d3.select(target)
    }

    return [rect, text]
  }

  function mouseOver(elementType, tooltipMessage) {
    return function(d, i) {
      tooltip
        .select('.content')
        .html(
          strings.formatString(
            tooltipMessage,
            '<strong>' + numeral(d.uplift).format('0.0') + '</strong>',
            '<strong>' + d.modalite + '</strong>',
            window.country
          )
        )

      const [rect, text] = elementsForData(this, elementType, d, i)

      rect
        .style('fill', '#FFFFFF')
        .style('stroke', '#000000')
        .style('stroke-width', '2px')

      text.style('font-weight', 'bold').style('fill', 'black')

      tooltip.style('display', 'block').style('opacity', 2)
    }
  }

  function mouseMove(d) {
    tooltip
      .style('top', d3.event.layerY + 10 + 'px')
      .style('left', d3.event.layerX - 25 + 'px')
  }

  function mouseOut(elementType) {
    return function(d, i) {
      const [rect, text] = elementsForData(this, elementType, d, i)

      rect.style('fill', pointColor).style('stroke', 'none')

      text.style('font-weight', 'normal').style('fill', 'grey')

      tooltip.style('display', 'none').style('opacity', 0)
    }
  }

  svg
    .selectAll(elemType)
    .on('mouseover', mouseOver(elemType, tooltipMessage))
    .on('mousemove', mouseMove)
    .on('mouseout', mouseOut(elemType))
}

export default spiralPlot
