import React from 'react'
import styles from './styles.module.css'

const ThankYouPage = () => {
  return (
    <div className={styles.thankyou}>
      <h2>Thank you for registering.</h2>
      <h4>
        The data distribution team will get in touch shortly and validate your
        account.{' '}
      </h4>
    </div>
  )
}

export default ThankYouPage
