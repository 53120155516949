import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'

import UpliftToolTip from '../../../Components/UpliftToolTip'
import { makeColorScale } from '../../../utils'
import strings from '../../../strings'

const colorScale = makeColorScale()
function barColor(d) {
  const color = colorScale(d.value)
  return color
}

class TopFlop extends PureComponent {
  render() {
    const { data, tooltipMessage } = this.props

    return (
      <ResponsiveBar
        data={data}
        keys={['uplift']}
        indexBy='modalite'
        margin={{
          top: 30,
          right: 0,
          bottom: 100,
          left: 50
        }}
        padding={0.3}
        colorBy={barColor}
        borderColor='inherit:darker(1.6)'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: null,
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: strings.dashboard.uplift,
          legendPosition: 'middle',
          legendOffset: -40
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor='inherit:darker(1.6)'
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={item => (
          <UpliftToolTip
            messageFormat={tooltipMessage}
            uplift={item.data.uplift}
            modalite={item.data.modalite}
          />
        )}
        theme={{
          tooltip: {
            container: {
              background: 'transparent',
              color: 'inherit',
              fontSize: 'inherit',
              borderRadius: '0',
              boxShadow: 'none',
              padding: '0',
              marginRight: '20px'
            }
          }
        }}
      />
    )
  }
}

TopFlop.propTypes = {
  data: PropTypes.array.isRequired,
  tooltipMessage: PropTypes.string.isRequired
}

export default TopFlop
