import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveTreeMap } from '@nivo/treemap'
import memoize from 'memoize-one'
import axios, { CancelToken } from 'axios'

import api from '../../api'
import strings from '../../strings'
import BlockLoader from '../BlockLoader'
import { makeColorScale } from '../../utils'
import UpliftToolTip from '../../Components/UpliftToolTip'

class TreeMap extends PureComponent {
  state = {
    isLoading: false,
    data: []
  }

  color = memoize(values => makeColorScale(1.3, 2, 4))

  constructor(props) {
    super(props)

    this.cancel = null
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillUnmount() {
    if (this.cancel) {
      this.cancel()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.country !== prevProps.country ||
      this.props.brand !== prevProps.brand ||
      this.props.model !== prevProps.model ||
      this.props.level !== prevProps.level ||
      this.props.population !== prevProps.population ||
      this.props.kle !== prevProps.kle
    ) {
      this.fetchData()
    }
  }

  cancelToken = () => {
    const that = this
    return new CancelToken(function executor(c) {
      that.cancel = c
    })
  }

  async fetchData() {
    if (this.props.country && this.props.brand) {
      if (!this.state.data.length) {
        this.setState({ isLoading: true })
      } else {
        // this.props.onLoadingChanged(true)
      }
      try {
        const response = await api.getUplift(
          this.props.country,
          this.cancelToken(),
          this.props.brand,
          this.props.model,
          this.props.level,
          this.props.population,
          this.props.kle
        )
        this.setState({ isLoading: false, data: response.data.data })
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err)
        }
      }
      this.cancel = null
    } else {
      this.setState({ data: [] })
    }
    this.props.onLoadingChanged(false)
  }

  render() {
    const { brand, population } = this.props
    const message = brand ? null : strings.dashboard.pleaseChooseBrand

    const color = this.color(this.state.data)

    const messageFormat =
      population === 'CountryPop'
        ? strings.dashboard.keyLifeEventsTooltipUsPopulation
        : strings.dashboard.keyLifeEventsTooltipAllBrands

    return (
      <BlockLoader loading={this.state.isLoading} message={message}>
        <ResponsiveTreeMap
          root={{
            modalite: 'No data available',
            color: '#ffffff',
            children: this.state.data
          }}
          leavesOnly
          identity='modalite'
          value='uplift'
          colorBy={d => (d.color ? d.color : color(d.uplift))}
          labelTextColor={d => (d.color ? '#cccccc' : '#ffffff')}
          innerPadding={10}
          tooltip={item =>
            item.data.modalite && item.data.uplift ? (
              <UpliftToolTip
                messageFormat={messageFormat}
                uplift={item.data.uplift}
                modalite={item.data.modalite}
              />
            ) : null
          }
          theme={{
            tooltip: {
              container: {
                background: 'transparent',
                color: 'inherit',
                fontSize: 'inherit',
                borderRadius: '0',
                boxShadow: 'none',
                padding: '0',
                marginRight: '20px'
              }
            }
          }}
        />
      </BlockLoader>
    )
  }
}

TreeMap.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  level: PropTypes.string,
  population: PropTypes.string,
  kle: PropTypes.bool,
  onLoadingChanged: PropTypes.func
}

TreeMap.defaultProps = {
  country: null,
  brand: null,
  model: null,
  level: null,
  population: null,
  kle: false
}

export default TreeMap
