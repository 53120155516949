import axios from 'axios'

const api = {
  getCountries: async () => {
    const countries = await axios.get('/api/cars/countries')
    return countries
  },

  getBrands: async country => {
    const brands = await axios.get('/api/cars/brands', { params: { country } })
    return brands
  },

  getModels: async (country, brand) => {
    const models = await axios.get('/api/cars/models', {
      params: { country, brand }
    })
    return models
  },

  getVolumes: async (country, brand, model) => {
    const volumes = await axios.get('/api/data/volumes', {
      params: { country, brand, model }
    })
    return volumes
  },

  getInterest: async (country, cancelToken, brand, model, level, from, to) => {
    const params = { country, brand }
    if (model) params.model = model
    if (level) params.level = level
    if (from) params.from = from
    if (to) params.to = to

    const volumes = await axios.get('/api/data/interest', {
      cancelToken,
      params
    })
    return volumes
  },

  getProximity: async (country, brand, model) => {
    const data = await axios.get('/api/data/proximity', {
      params: { country, brand, model }
    })
    return data
  },

  getMarket: async (country, brand, model) => {
    const data = await axios.get('/api/data/market', {
      params: { country, brand, model }
    })
    return data
  },

  getUplift: async (country, cancelToken, brand, model, level, ref, kle) => {
    const data = await axios.get('/api/data/uplift', {
      params: { country, brand, model, level, ref, kle }
    })
    return data
  },

  getGeoloc: async (country, brand, model) => {
    const dots = await axios.get('/api/data/geoloc', {
      params: { country, brand, model }
    })
    return dots
  },

  getHeatmapGeoloc: async (country, brand, model) => {
    const dots = await axios.get('/api/data/mapstate', {
      params: { country, brand, model }
    })
    return dots
  },

  getUsers: async cancelToken => {
    const data = await axios.get('/api/users/users', { cancelToken })
    return data
  },

  getUser: async (cancelToken, id) => {
    const data = await axios.get(`/api/users/users/${id}`, { cancelToken })
    return data
  },

  createUser: async (cancelToken, user) => {
    const data = await axios.post('/api/users/users', user, { cancelToken })
    return data
  },

  updateUser: async (cancelToken, id, user) => {
    const data = await axios.post(`/api/users/users/${id}`, user, {
      cancelToken
    })
    return data
  },

  deleteUser: async (cancelToken, id) => {
    const data = await axios.delete(`/api/users/users/${id}`, { cancelToken })
    return data
  }
}

export default api
