import React, { useEffect, useState, useCallback } from 'react'
import { renderToString } from 'react-dom/server'
import L from 'leaflet'
import 'leaflet.heat'
import 'leaflet/dist/leaflet.css'

import { Button, Segment } from 'semantic-ui-react'

import {
  Map,
  TileLayer,
  LayersControl,
  FeatureGroup,
  GeoJSON
} from 'react-leaflet'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import Control from './Control'

import { makeColorScale, formatNumber } from '../../utils'
import api from '../../api'

const style = {
  height: '700px',
  zIndex: 0
}

const viewportOf = country =>
  ({
    ES: { center: [36.138, -6.899], zoom: 5 },
    FR: { center: [46.498, 2.208], zoom: 6 },
    IT: { center: [41.459, 12.7], zoom: 6 },
    PT: { center: [37.234, -17.873], zoom: 6 },
    RU: { center: [57.421, 34.453], zoom: 4 },
    US: { center: [36.03, -111.45], zoom: 4 }
  }[country])

const SecondMap = props => {
  const myref = React.useRef(null)
  const [viewport, setViewport] = useState(viewportOf(props.country || 'FR'))
  const [zoom, setZoom] = useState(viewport.zoom)
  const [data, setData] = useState([])
  const [dots, setDots] = useState([viewport.center])

  useEffect(() => {
    if (props.country === '' || props.brand === '' || props.model === '') return

    const fetchDataHeatmap = async () => {
      const result = await api.getHeatmapGeoloc(
        props.country,
        props.brand,
        props.model
      )
      setData(result.data.data)
    }

    const fetchDataState = async () => {
      const result = await api.getGeoloc(
        props.country,
        props.brand,
        props.model
      )
      setDots(result.data.data.map(d => [d[0], d[1], Math.log(d[2])]))
    }

    fetchDataState()
    fetchDataHeatmap()
    setViewport(viewportOf(props.country))
  }, [props.country, props.brand, props.model])

  const geojson = {
    type: 'FeatureCollection',
    features: data.map(d => ({
      type: 'Feature',
      properties: {
        name: d.state,
        volume: d.state_vol,
        density: d.state_pct1000,
        uplift: d.uplift_state_pct1000
      },
      geometry: d.polygon.geometry
    }))
  }

  const popupText = useCallback((feature, layer) => {
    const { name, uplift, density } = feature.properties
    const pct = Number(density).toFixed(2)
    const upf = Number(uplift).toFixed(2)

    layer.bindPopup(
      renderToString(
        <>
          In <b>{name}</b>, <b>{pct}</b>% of inhabitants are interested{' '}
          <b>{upf}</b> time {upf > 1 ? 'more' : 'less'} than {window.country}'
          population
        </>
      )
    )
  })

  useEffect(() => {
    if (myref.current?.leafletElement) {
      setZoom(myref.current?.leafletElement.getZoom())
    }
  }, [zoom, myref.current])

  return (
    <Map
      viewport={viewport}
      zoomSnap={0.25}
      style={style}
      ref={myref}
      attributionControl={false}>
      <Control position='bottomright'>
        <Segment style={{ width: 200 }}>
          <h3>
            In the <b>{props.country}</b> market,{' '}
            {formatNumber(Math.round(window.volumes))} unique users.
          </h3>
        </Segment>
      </Control>

      <Control position='bottomright'>
        <Button
          primary
          size='tiny'
          style={{
            background: `linear-gradient(90deg, ${makeColorScale(
              0.8,
              1,
              1.2
            )(0.8)} 0%, ${makeColorScale(0.8, 1, 1.2)(1)} 50%, ${makeColorScale(
              0.8,
              1,
              1.2
            )(1.2)} 100%)`
          }}>
          Weak to Strong Uplift
        </Button>
      </Control>

      <LayersControl position='topright'>
        <LayersControl.BaseLayer name='Heatmap'>
          <FeatureGroup>
            <TileLayer
              url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
              noWrap
            />
            <HeatmapLayer
              points={dots}
              gradient={{
                0.33: makeColorScale(0.33, 0.66, 1)(zoom ** 0.1 * 2),
                0.66: makeColorScale(0.33, 0.66, 1)(zoom ** 1.5 * 2),
                1: makeColorScale(0.33, 0.66, 1)(zoom ** 3 * 2)
              }}
              longitudeExtractor={m => m[1]}
              latitudeExtractor={m => m[0]}
              intensityExtractor={m => parseFloat(m[2] / 2)}
              blur={1}
              radius={zoom}
            />
          </FeatureGroup>
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='State' checked>
          <FeatureGroup>
            <TileLayer
              url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png'
              noWrap
            />
            <GeoJSON
              data={geojson}
              onEachFeature={(feature, layer) => {
                popupText(feature, layer)
              }}
              style={({ properties }) => ({
                color: 'white',
                weight: 1,
                fillColor: makeColorScale(0.8, 1, 1.2)(properties.uplift),
                fillOpacity: 0.8
              })}
              key={Date.now()}
            />
          </FeatureGroup>
        </LayersControl.BaseLayer>
      </LayersControl>
    </Map>
  )
}

const MemoedSecondMap = React.memo(SecondMap)

export { MemoedSecondMap as SecondMap }
