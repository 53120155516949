import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from 'semantic-ui-react'
import numeral from 'numeral'

import AnimatedCounter from '../AnimatedCounter'
import styles from './styles.module.css'
import strings from '../../../strings'
import { capitalize } from '../../../utils'

const SUIRColors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
  'facebook',
  'google plus',
  'instagram',
  'linkedin',
  'twitter',
  'vk',
  'youtube'
]

class LevelButton extends PureComponent {
  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    let {
      color,
      label,
      number,
      country,
      brand,
      model,
      level,
      active
    } = this.props
    let clazz = undefined
    if (!active) {
      color = 'black'
    } else if (!SUIRColors.includes(color)) {
      clazz = color
      color = undefined
    }

    const num = numeral(number)

    let helpMessage = ''
    if (number && brand) {
      const formattedNum = num.format('0,0.0a').toUpperCase()
      helpMessage = model
        ? strings.formatString(
            strings.dashboard.interestTooltipModel,
            <strong>{country}</strong>,
            <strong>{formattedNum}</strong>,
            <strong>{capitalize(brand)}</strong>,
            <strong>{capitalize(model)}</strong>,
            <strong>{level}</strong>
          )
        : strings.formatString(
            strings.dashboard.interestTooltipBrand,
            <strong>{country}</strong>,
            <strong>{formattedNum}</strong>,
            <strong>{capitalize(brand)}</strong>,
            <strong>{level}</strong>
          )
    }

    return (
      <Button.Group size='mini' className={styles.volumeButton}>
        <Button className={clazz} color={color} onClick={this.onClick}>
          {label}
        </Button>
        <Popup
          trigger={
            <Button
              className={clazz}
              color={color}
              basic
              onClick={this.onClick}>
              <AnimatedCounter value={numeral(number).format()} />
            </Button>
          }
          content={helpMessage}
          basic
          hoverable
        />
      </Button.Group>
    )
  }
}

LevelButton.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  number: PropTypes.number,
  brand: PropTypes.string,
  model: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
}

export default LevelButton
