import React, { PureComponent } from 'react'
import axios, { CancelToken } from 'axios'
import { Form, Message, Grid, Button, Loader, Confirm } from 'semantic-ui-react'
import emailValidator from 'email-validator'
import { withRouter } from 'react-router-dom'

import { isEmpty } from '../../utils'

import PageBlock from '../../Components/PageBlock'
import api from '../../api'
import strings from '../../strings'
import styles from './styles.module.css'

const LAST_LOGIN_FORMAT = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
}

class UserDetailsPage extends PureComponent {
  cancel = null

  constructor(props) {
    super(props)

    const id = this.props.match.params.id

    this.state = {
      loading: id !== 'new',
      submitting: false,
      deleting: false,
      confirmDelete: false,
      error: null
    }
  }

  render() {
    const {
      loading,
      submitting,
      deleting,
      confirmDelete,
      error,
      actif,
      admin,
      company,
      email,
      firstname,
      last_login,
      lastname,
      position,
      password,
      confirmPassword
    } = this.state

    const id = this.props.match.params.id

    const isActive = !!actif
    const isAdmin = !!admin
    const lastLogin = last_login ? new Date(last_login) : null
    const disabled = submitting || deleting

    return (
      <PageBlock>
        {loading ? (
          <Loader active />
        ) : (
          <div className={styles.root}>
            <Form error={!!error} noValidate>
              {error && (
                <Message
                  error
                  header={strings.admin.users.errors.title}
                  content={error.message || strings.admin.users.errors.message}
                />
              )}
              {(id !== 'new' || lastLogin) && (
                <Form.Group widths='equal' className={styles.identifier}>
                  {id !== 'new' && (
                    <Form.Field inline>
                      <label>{strings.admin.users.id}:</label>
                      {id}
                    </Form.Field>
                  )}
                  {lastLogin && (
                    <Form.Field inline>
                      <label>{strings.admin.users.lastLogin}:</label>
                      {lastLogin.toLocaleDateString(
                        undefined,
                        LAST_LOGIN_FORMAT
                      )}
                    </Form.Field>
                  )}
                </Form.Group>
              )}
              <Form.Input
                onChange={this.onChange}
                label={strings.admin.users.email}
                name='email'
                placeholder={strings.admin.users.emailAddress}
                autoComplete='email'
                value={email || ''}
                required
                width={8}
                disabled={disabled}
                error={error && error.hasOwnProperty('email')}
              />
              {error && error.hasOwnProperty('email') && (
                <Grid>
                  <Grid.Column width={8}>
                    <Message error content={error.email} />
                  </Grid.Column>
                </Grid>
              )}
              <Form.Group widths='equal'>
                <Form.Input
                  onChange={this.onChange}
                  fluid
                  label={strings.admin.users.firstName}
                  name='firstname'
                  placeholder={strings.admin.users.firstName}
                  autoComplete='given-name'
                  required
                  value={firstname || ''}
                  disabled={disabled}
                  error={error && error.hasOwnProperty('firstname')}
                />
                <Form.Input
                  onChange={this.onChange}
                  fluid
                  label={strings.admin.users.lastName}
                  name='lastname'
                  placeholder={strings.admin.users.lastName}
                  autoComplete='family-name'
                  required
                  value={lastname || ''}
                  disabled={disabled}
                  error={error && error.hasOwnProperty('lastname')}
                />
              </Form.Group>
              {error &&
                (error.hasOwnProperty('firstname') ||
                  error.hasOwnProperty('lastname')) && (
                  <Grid>
                    <Grid.Column width={8}>
                      <Message error content={error.firstname} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Message error content={error.lastname} />
                    </Grid.Column>
                  </Grid>
                )}
              <Form.Group widths='equal'>
                <Form.Input
                  onChange={this.onChange}
                  fluid
                  label={strings.admin.users.company}
                  name='company'
                  placeholder={strings.admin.users.company}
                  autoComplete='organization'
                  value={company || ''}
                  disabled={disabled}
                />
                <Form.Input
                  onChange={this.onChange}
                  fluid
                  label={strings.admin.users.position}
                  name='position'
                  placeholder={strings.admin.users.position}
                  autoComplete='organization-title'
                  value={position || ''}
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input
                  onChange={this.onChange}
                  fluid
                  label={
                    id === 'new'
                      ? strings.admin.users.password
                      : strings.admin.users.changePassword
                  }
                  name='password'
                  placeholder={strings.admin.users.password}
                  type='password'
                  autoComplete='new-password'
                  value={password || ''}
                  required={id === 'new'}
                  disabled={disabled}
                />
                <Form.Input
                  onChange={this.onChange}
                  fluid
                  label={strings.admin.users.confirmPassword}
                  name='confirmPassword'
                  placeholder={strings.admin.users.enterPasswordConfirm}
                  type='password'
                  autoComplete='new-password'
                  value={confirmPassword || ''}
                  error={error && error.hasOwnProperty('confirmPassword')}
                  required={id === 'new'}
                  disabled={disabled}
                />
              </Form.Group>
              {error &&
                (error.hasOwnProperty('password') ||
                  error.hasOwnProperty('confirmPassword')) && (
                  <Grid>
                    <Grid.Column width={8}>
                      <Message error content={error.password} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Message error content={error.confirmPassword} />
                    </Grid.Column>
                  </Grid>
                )}
              <Form.Group widths='equal'>
                <Form.Checkbox
                  onChange={this.onChange}
                  label={strings.admin.users.active}
                  name='actif'
                  checked={isActive}
                  disabled={disabled}
                />
                <Form.Checkbox
                  onChange={this.onChange}
                  label={strings.admin.users.admin}
                  name='admin'
                  checked={isAdmin}
                  disabled={disabled}
                />
              </Form.Group>
              {error &&
                (error.hasOwnProperty('actif') ||
                  error.hasOwnProperty('admin')) && (
                  <Grid>
                    <Grid.Column width={8}>
                      <Message error content={error.actif} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Message error content={error.admin} />
                    </Grid.Column>
                  </Grid>
                )}
              <div className={styles.buttons}>
                {id !== 'new' && (
                  <>
                    <Button
                      floated='left'
                      onClick={this.onDelete}
                      negative
                      disabled={disabled}
                      loading={deleting}>
                      {strings.admin.users.delete}
                    </Button>
                    <Confirm
                      open={confirmDelete}
                      header={strings.admin.users.confirmDeleteTitle}
                      content={strings.admin.users.confirmDeleteMessage}
                      confirmButton={
                        <Button negative>{strings.admin.users.delete}</Button>
                      }
                      onCancel={this.onDeleteCancel}
                      onConfirm={this.onDeleteConfirm}
                    />
                  </>
                )}
                <Button
                  floated='right'
                  onClick={this.onSave}
                  type='submit'
                  primary
                  loading={submitting}
                  disabled={disabled}>
                  {strings.admin.users.save}
                </Button>
                <Button
                  floated='right'
                  onClick={this.onCancel}
                  disabled={disabled}>
                  {strings.admin.users.cancel}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </PageBlock>
    )
  }

  componentWillUnmount() {
    if (this.cancel) {
      this.cancel()
      this.cancel = null
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  cancelToken = () => {
    const that = this
    return new CancelToken(function executor(c) {
      that.cancel = c
    })
  }

  async fetchData() {
    const id = this.props.match.params.id
    if (id === 'new') {
      return
    }
    try {
      const data = await api.getUser(this.cancelToken(), id)
      const user = data.data.data
      this.setState({ ...user, loading: false })
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err)
      }
    }
    this.cancel = null
  }

  onChange = (e, data) => {
    const name = data.name
    let value = null
    if (data.hasOwnProperty('checked')) {
      value = data.checked
    } else {
      value = e.target.value
    }
    this.setState((state, props) => {
      const newState = { ...state, [name]: value }
      if (newState.error) {
        newState.error = this.validate(newState)
      }
      return newState
    })
  }

  validate = (state = null) => {
    const { email, firstname, lastname, password, confirmPassword } =
      state || this.state

    const errors = {}
    if (!emailValidator.validate(email)) {
      errors.email = strings.admin.users.errors.email
    }
    if (!firstname) {
      errors.firstname = strings.admin.users.errors.firstName
    }
    if (!lastname) {
      errors.lastname = strings.admin.users.errors.lastName
    }

    if (this.props.match.params.id === 'new' && !password) {
      errors.password = strings.admin.users.errors.password
    }

    if (password) {
      if (!confirmPassword) {
        errors.confirmPassword = strings.admin.users.errors.confirmPasswordEmpty
      } else if (confirmPassword !== password) {
        errors.confirmPassword =
          strings.admin.users.errors.confirmPasswordDoesNotMatch
      }
    }

    return isEmpty(errors) ? null : errors
  }

  onCancel = () => {
    this.props.history.goBack()
  }

  onSave = () => {
    const error = this.validate()
    if (!error) {
      this.saveUser()
    }
    this.setState({ error })
  }

  onDelete = () => {
    this.setState({ confirmDelete: true })
  }

  onDeleteCancel = () => {
    this.setState({ confirmDelete: false })
  }

  onDeleteConfirm = () => {
    this.setState({ confirmDelete: false })
    this.deleteUser()
  }

  async deleteUser() {
    this.setState({ deleting: true })
    const id = this.props.match.params.id
    try {
      const result = await api.deleteUser(this.cancelToken(), id)
      this.setState({ deleting: false })
      let error = null
      switch (result.data.status) {
        case 'success':
          this.props.history.goBack()
          break
        case 'fail':
          error = result.data.data
          break
        case 'error':
          error = {
            message: result.data.message
          }
          break
        default:
          console.error(result)
          break
      }
      if (error) {
        this.setState({ error })
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err)
      }
    }
    this.cancel = null
  }

  async saveUser() {
    const {
      actif,
      admin,
      company,
      email,
      firstname,
      lastname,
      position,
      password
    } = this.state
    const user = {
      actif,
      admin,
      company,
      email,
      firstname,
      lastname,
      position,
      password
    }
    this.setState({ submitting: true })
    const id = this.props.match.params.id
    try {
      let result = {}
      if (id === 'new') {
        result = await api.createUser(this.cancelToken(), user)
      } else {
        result = await api.updateUser(this.cancelToken(), id, user)
      }
      this.setState({ submitting: false })
      let error = null
      switch (result.data.status) {
        case 'success':
          this.props.history.goBack()
          break
        case 'fail':
          error = result.data.data
          break
        case 'error':
          error = {
            message: result.data.message
          }
          break
        default:
          console.error(result)
          break
      }
      if (error) {
        this.setState({ error })
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err)
      }
    }
    this.cancel = null
  }
}

export default withRouter(UserDetailsPage)
