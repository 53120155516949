import React, { PureComponent } from 'react'
import Reel from 'react-reel'
import classNames from 'classnames'

import styles from './styles.module.css'

class AnimatedCounter extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const initial =
      !prevState.value ||
      (prevState.initial && nextProps.value === prevState.value)
    return {
      value: nextProps.value,
      initial
    }
  }

  state = {
    value: null,
    initial: true
  }

  render() {
    const { initial } = this.state

    return (
      <div
        className={classNames({
          [styles.wrapper]: true,
          [styles.initial]: initial
        })}>
        <Reel text={this.props.value} theme={styles} />
        {initial && <div className={styles.value}>{this.props.value}</div>}
      </div>
    )
  }
}

export default AnimatedCounter
