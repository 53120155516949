import React, { PureComponent } from 'react'
import { Network, Node, Edge } from '@lifeomic/react-vis-network'
import PropTypes from 'prop-types'

import BlockLoader from '../../Components/BlockLoader'
import Thumbnail from '../../Components/Thumbnail'
import CarContext from '../../Components/CarContext'
import api from '../../api'
import genericCar from '../../Components/Thumbnail/car.png'
import strings from '../../strings'
import { capitalize } from '../../utils'
import styles from './styles.module.css'

const NETWORK_OPTIONS = {
  /*
  configure: {
    enabled: true
  },
  */
  nodes: {
    scaling: { min: 20, max: 50 },
    font: {
      strokeWidth: 3,
      strokeColor: '#FFFFFF'
    }
  },
  edges: {
    color: {
      color: '#DDDDDD'
    },
    font: {
      color: '#ffffff',
      size: 16,
      strokeWidth: 0,
      background: '#C0172C'
    }
  },
  interaction: {
    hover: true,
    dragNodes: false,
    dragView: false,
    zoomView: false,
    tooltipDelay: 0
  },
  physics: {
    enabled: false
  }
}

class Proximity extends PureComponent {
  state = {
    isLoading: false,
    models: []
  }

  networkRef = React.createRef()

  render() {
    const { brand, model } = this.props
    const { models, isLoading } = this.state
    let message = null
    if (brand) {
      if (!model) {
        message = strings.dashboard.pleaseChooseModel
      }
    } else {
      message = strings.dashboard.pleaseChooseBrandAndModel
    }

    let { min, max } = models.reduce(
      (accu, mdl) => {
        let { min, max } = accu
        const { score } = mdl
        if (score > max) {
          max = score
        }
        if (score < min) {
          min = score
        }
        return { min, max }
      },
      { min: Number.MAX_SAFE_INTEGER, max: 0 }
    )

    const max3 = max / 3
    if (max3 > min) {
      min = max3
    }

    return (
      <BlockLoader
        loading={isLoading}
        message={message}
        className={styles.root}>
        {!message && (
          <Network options={NETWORK_OPTIONS} ref={this.networkRef}>
            {this.carNode({ brand, model })}
            {models.map(this.carNode)}
            {models.map(this.carEdge)}
          </Network>
        )}
      </BlockLoader>
    )
  }

  componentDidMount() {
    this.fetchData()
  }

  onCarClick = params => {
    if (params.nodes && params.nodes.length) {
      const node = params.nodes[0]
      const elems = node.split('|')
      const [brand, model] = elems
      this.context.configureCar(brand, model)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.country !== prevProps.country ||
      this.props.brand !== prevProps.brand ||
      this.props.model !== prevProps.model
    ) {
      this.fetchData()
    }
  }

  async fetchData() {
    if (this.props.brand && this.props.model) {
      if (this.state.models.length === 0) {
        this.setState({ isLoading: true })
      } else {
        this.props.onLoadingChanged(true)
      }
      const response = await api.getProximity(
        this.props.country,
        this.props.brand,
        this.props.model
      )
      this.setState({ isLoading: false, models: response.data.data.reverse() })
    } else {
      this.setState({ models: [] })
    }
    this.props.onLoadingChanged(false)
    if (this.networkRef.current) {
      this.networkRef.current.network.on('click', this.onCarClick)
    }
  }

  carEdge = ({ brand, model, score }) => {
    const centerCar = carNodeId(this.props.brand, this.props.model)
    const car = carNodeId(brand, model)
    const name = carName(brand, model)
    const length = edgeLength(score)
    const label = ` ${score} `

    return (
      <Edge
        shadow={false}
        id={car}
        from={centerCar}
        to={car}
        key={`edge:${car}`}
        length={length}
        label={label}
        title={tooltip(centerCar, name, score)}
      />
    )
  }

  carNode = ({ brand, model, score }, index, cars) => {
    const centerCar = carName(this.props.brand, this.props.model)
    const carId = carNodeId(brand, model)
    const name = carName(brand, model)

    let x, y
    if (cars) {
      const length = edgeLength(score)
      const nbCars = cars.length
      index = nbCars - 1 - index
      const angleRad = (2 * Math.PI * index) / nbCars - Math.PI / 2
      x = length * Math.cos(angleRad)
      y = length * Math.sin(angleRad)
    } else {
      x = 0
      y = 0
    }

    return (
      <Node
        id={carId}
        label={name}
        key={carId}
        shape='image'
        image={
          model.includes('GLOBAL')
            ? Thumbnail.carImage(brand)
            : Thumbnail.carImage(brand, model)
        }
        brokenImage={genericCar}
        value={20}
        title={score ? tooltip(centerCar, name, score) : null}
        x={x}
        y={y}
      />
    )
  }
}

function wrapTooltip(tooltip) {
  return `<div style="width: 250px">
    ${tooltip}
  </div>`
}

function carNodeId(brand, model) {
  return `${brand}|${model}`
}

function carName(brand, model) {
  return `${capitalize(brand)} ${
    model.includes('GLOBAL') ? '' : capitalize(model)
  }`
}

function edgeLength(score) {
  const length = 250 - score * 4
  return Math.max(50, length)
}

function tooltip(centerCar, car, score) {
  const close =
    score > 19
      ? 'an exact match'
      : score > 15
      ? 'a very similar'
      : score > 10
      ? 'a similar'
      : score > 5
      ? 'an apparented'
      : 'a normal'

  return wrapTooltip(
    strings.formatString(
      strings.dashboard.proximityTooltip,
      `<strong>${centerCar}</strong>`,
      `<strong>${car}</strong>`,
      `<strong>${score}</strong>`,
      close
    )
  )
}

Proximity.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  onLoadingChanged: PropTypes.func
}

Proximity.defaultProps = {
  country: null,
  brand: null,
  model: null
}

Proximity.contextType = CarContext

export default Proximity
