import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'

import spiralPlot from './spiralplot'
import styles from './styles.module.css'

class SpiralPlot extends PureComponent {
  constructor(props) {
    super(props)

    this.el = null
    this.dimensions = null
  }

  componentDidMount() {
    this.displaySpiral()
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.displaySpiral()
    }
  }

  displaySpiral = (animate = true) => {
    if (this.el === null || this.dimensions === null) {
      return
    }

    spiralPlot(
      this.el,
      this.dimensions,
      this.props.data,
      this.props.tooltipMessage,
      animate
    )
  }

  render() {
    return (
      <Measure
        bounds
        innerRef={el => (this.el = el)}
        onResize={contentRect => {
          const newDim = contentRect.bounds
          const isFirstDraw = this.dimensions === null
          if (
            isFirstDraw ||
            this.dimensions.width !== newDim.width ||
            this.dimensions.height !== newDim.height
          ) {
            this.dimensions = contentRect.bounds
            this.displaySpiral(isFirstDraw)
          }
        }}>
        {({ measureRef, contentRect }) => (
          <div className={styles.spiral} ref={measureRef}></div>
        )}
      </Measure>
    )
  }
}

SpiralPlot.propTypes = {
  data: PropTypes.array.isRequired,
  tooltipMessage: PropTypes.string.isRequired
}

export default SpiralPlot
