import React from 'react'

import TopTen from '../../Components/TopTen'
import api from '../../api'

const MarketVision = props => {
  return <TopTen {...props} dataFetcher={api.getMarket} />
}

MarketVision.defaultProps = {
  country: null,
  brand: null,
  model: null
}

export default MarketVision
