import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'

// import auth from '../auth'
import PageBlock from '../Components/PageBlock'
import DashBlock from '../Components/DashBlock'
import Settings from './Settings'
import Behavioral from './Behavioral'
// import Interest from './Interest'
import KeyLifeEvents from './KeyLifeEvents'
import MarketVision from './MarketVision'
import Proximity from './Proximity'
import Volumes from './Volumes'
import strings from './../strings'
import styles from './styles.module.css'
// import PopulationSettings from "./PopulationSettings"
import CarContext from '../Components/CarContext'
import { SecondMap } from '../Components/Heatmap'

class DashboardPage extends Component {
  state = {
    brand: 'AUDI',
    model: 'A1',
    level: null,
    reference: 'CountryPop',
    country: 'FR'
  }

  componentDidMount() {
    window.country = this.state.country
  }

  configureCar = (brand, model) => {
    this.setState({ brand, model })
  }

  onCountryChanged = country => {
    window.country = country
    if (country === '') this.setState({ country: 'FR' })
    this.setState({ country, model: '', brand: '' })
  }

  onBrandChanged = brand => {
    this.setState({ brand, model: `GLOBAL ${brand}` })
  }

  onModelChanged = model => {
    this.setState({ model })
  }

  onReferenceChanged = reference => {
    this.setState({ reference })
  }

  onLevelClicked = level => {
    if (level === this.state.level) {
      level = null
    }
    this.setState({ level })
  }

  render() {
    const { country, brand, model, level, reference } = this.state
    return (
      <div>
        <CarContext.Provider value={{ configureCar: this.configureCar }}>
          <PageBlock>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                  widescreen={16}>
                  <DashBlock>
                    <Settings
                      onCountryChanged={this.onCountryChanged}
                      onBrandChanged={this.onBrandChanged}
                      onModelChanged={this.onModelChanged}
                      country={country}
                      brand={brand}
                      model={model}
                    />
                  </DashBlock>
                </Grid.Column>
                {/* <Grid.Column tablet={5} computer={6} largeScreen={5} widescreen={5}>
                  <DashBlock>
                    <PopulationSettings
                      onReferenceChanged={this.onReferenceChanged}
                      population={reference}
                    />
                  </DashBlock>
                </Grid.Column>*/}
              </Grid.Row>
              <Grid.Column
                tablet={5}
                computer={6}
                largeScreen={3}
                widescreen={3}
                className='step-a'>
                <DashBlock
                  className={styles.volumes}
                  title={strings.dashboard.volumes}>
                  <Volumes
                    country={country}
                    brand={brand}
                    model={model}
                    level={level}
                    onLevelClicked={this.onLevelClicked}
                  />
                </DashBlock>
              </Grid.Column>
              <Grid.Column
                tablet={11}
                computer={10}
                largeScreen={8}
                widescreen={8}
                className='step-b'>
                <DashBlock
                  className={styles.behavior}
                  title={strings.dashboard.behavioral}>
                  <Behavioral
                    // displayModes={admin}
                    displayModes={'all'}
                    country={country}
                    brand={brand}
                    model={model}
                    level={level}
                    population={reference}
                  />
                </DashBlock>
              </Grid.Column>
              <Grid.Column
                tablet={4}
                computer={4}
                largeScreen={5}
                widescreen={5}
                className='step-c'>
                <DashBlock className={styles.kle} title={strings.dashboard.kle}>
                  <KeyLifeEvents
                    country={country}
                    brand={brand}
                    model={model}
                    level={level}
                    population={reference}
                  />
                </DashBlock>
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={6}
                largeScreen={8}
                widescreen={8}
                className='step-d'>
                <DashBlock
                  component={MarketVision}
                  className={styles.vision}
                  title={strings.dashboard.marketVision}
                  country={country}
                  brand={brand}
                  model={model}
                />
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={6}
                largeScreen={8}
                widescreen={8}
                className='step-e'>
                <DashBlock
                  component={Proximity}
                  className={styles.proximity}
                  title={strings.dashboard.proximity}
                  country={country}
                  brand={brand}
                  model={model}
                  key={`${brand} ${model}`}
                />
              </Grid.Column>
              <Grid.Column
                tablet={16}
                computer={16}
                largeScreen={16}
                widescreen={16}
                className='step-f'>
                <DashBlock className={styles.interest} title={`Heatmap`} fluid>
                  {/* <Heatmap country={country} brand={brand} model={model} /> */}
                  <SecondMap country={country} brand={brand} model={model} />
                </DashBlock>
              </Grid.Column>
            </Grid>
          </PageBlock>
        </CarContext.Provider>
      </div>
    )
  }
}

export default DashboardPage
