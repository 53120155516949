import { scaleLinear } from 'd3'

const colorMin = '#002060'
const colorOne = '#9AA6AC'
const colorMax = '#C0172C'
const globalFont = "'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif"

const RANGE_MIN = 1.5
const RANGE_AVG = 2
const RANGE_MAX = 5
function makeColorScale(
  rangeMin = RANGE_MIN,
  rangeAvg = RANGE_AVG,
  rangeMax = RANGE_MAX
) {
  const colorDomain = [rangeMin, rangeAvg, rangeMax]
  const colorRange = [colorMin, colorOne, colorMax]

  const color = scaleLinear()
    .domain(colorDomain)
    .range(colorRange)
    .clamp(true)

  return color
}

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

const isDate = date =>
  date &&
  Object.prototype.toString.call(date) === '[object Date]' &&
  !isNaN(date)

const toSnakeCase = str =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_')

const capitalize = str =>
  str
    .split(' ')
    .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`)
    .join(' ')

const isEmpty = obj => obj == null || !(Object.keys(obj) || obj).length

const orderBy = (arr, props, orders) =>
  [...arr].sort((a, b) =>
    props.reduce((acc, prop, i) => {
      if (acc === 0) {
        const [p1, p2] =
          orders && orders[i] === 'desc'
            ? [b[prop], a[prop]]
            : [a[prop], b[prop]]
        acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0
      }
      return acc
    }, 0)
  )

const formatNumber = n => {
  const base = Math.floor(Math.log(Math.abs(n)) / Math.log(1000))
  const suffix = 'KMB'[base - 1]
  return suffix
    ? String(n / Math.pow(1000, base)).substring(0, 3) + suffix
    : '' + n
}

export {
  colorMin,
  colorOne,
  colorMax,
  makeColorScale,
  globalFont,
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  formatNumber,
  isEmpty,
  isDate,
  toSnakeCase,
  capitalize,
  orderBy
}
