import React, { PureComponent } from 'react'
import { Dropdown } from 'semantic-ui-react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { capitalize } from '../../../utils'

class Picker extends PureComponent {
  options = memoize(values =>
    values.map(value => ({
      text: value.length === 2 ? value : capitalize(value),
      value
    }))
  )

  onSelected = (e, { value }) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <Dropdown
        id={this.props.id || 'picker'}
        placeholder={this.props.placeholder}
        fluid
        search
        selection
        value={this.props.value}
        onChange={this.onSelected}
        loading={this.props.loading}
        disabled={this.props.disabled}
        options={this.options(this.props.data)}
      />
    )
  }
}

Picker.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func
}

Picker.defaultProps = {
  disabled: false,
  loading: false,
  value: null
}

export default Picker
