import React, { Component } from 'react'

import TreeMap from '../../Components/TreeMap'

class KeyLifeEvents extends Component {
  render() {
    return <TreeMap kle={true} {...this.props} />
  }
}

export default KeyLifeEvents
