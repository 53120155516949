import React from 'react'
import { Loader } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

const BlockLoader = props => {
  const { loading, message, className, measureRef } = props
  const showMessage = message && !loading
  const showChildren = !message && !loading

  return (
    <div ref={measureRef} className={classNames(styles.blockLoader, className)}>
      {(loading || showMessage) && (
        <div className={styles.wrapper}>
          {loading && <Loader active />}
          {showMessage && <p>{message}</p>}
        </div>
      )}
      {showChildren && props.children}
    </div>
  )
}

BlockLoader.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string,
  measureRef: PropTypes.any
}

BlockLoader.defaultProps = {
  loading: false,
  message: null,
  className: null
}

export default BlockLoader
