import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Menu, Container, Icon, Button as Link } from 'semantic-ui-react'
import { Walktour } from 'walktour'
import { withRouter } from 'react-router'

import styles from './styles.module.css'
import strings from '../../strings'
import auth from '../../auth'

class Header extends Component {
  state = {
    tourOpen: false,
    steps: [
      {
        selector: '.step-a',
        description: `<p>At Weborama, we believe that people <b>have more
        than a binary vision of things</b> : they are more
        than “interested” or “non-interested” in a topic.</p>
        
        <p>For this reason, <b>each market segment is split into
        4 sub-segments</b>, depending on the users’
        intensity of signals left on the web.</p>
        
        <ul>
        <li><b>Intenders</b> : most interested 25% of the market
        segments. They have an intense content
        consumption about the selected model.</li>
        <li><b>Interested</b> : bottom 25%, only few weak signals were
        captured for these users about the selected
        model.</li>
        </ul>`,
        customDescriptionRenderer: (str, tourLogic) => (
          <div dangerouslySetInnerHTML={{ __html: str }} />
        ),
        orientationPreferences: ['east-south']
      },
      {
        selector: '.step-b',
        description: `<p>At Weborama, we believe that <b>people have
        multiple facets.</b></p>
        
        <p>An individual, while flagged as a <b>“BMW X3
        intender”</b>, is also profiled against hundreds of
        centers of interests thanks to a unique
        methodology.</p>
        `,
        customDescriptionRenderer: (str, tourLogic) => (
          <div dangerouslySetInnerHTML={{ __html: str }} />
        ),
        orientationPreferences: ['west-north']
      },
      {
        selector: '.step-c',
        description: `<p>At Weborama, we follow people in everything
        they go through.</p>
        
        <p>Moving, expecting a child, retiring… many life
        stages are structured in our database and allow
        you to identify which one of them drives the
        intent towards each model.</p>`,
        customDescriptionRenderer: (str, tourLogic) => (
          <div dangerouslySetInnerHTML={{ __html: str }} />
        ),
        orientationPreferences: ['west-north']
      },
      {
        selector: '.step-d',
        description: `<p>Jump from a model to its main competitors, to
        understand the differences between the
        behavioral profiles of their intenders.</p>`,
        customDescriptionRenderer: (str, tourLogic) => (
          <div dangerouslySetInnerHTML={{ __html: str }} />
        ),
        orientationPreferences: ['east-north']
      },
      {
        selector: '.step-e',
        description: `<p>Weborama wants to give a fresh look at the
        automotive market on the web.</p>
        
        <p>By comparing behavioral profiles of different car
        model intenders, thanks to complex machine
        learning algorithms, Weborama is able to provide
        you with the <b>top 10 models that attract the same
        kind of user profiles as the selected model.</b></p>
        
        <p>In a nutshell, discover the competitive landscape
        of each model from a new perspective : the
        customer’s.</p>`,
        customDescriptionRenderer: (str, tourLogic) => (
          <div dangerouslySetInnerHTML={{ __html: str }} />
        ),
        orientationPreferences: ['west-north']
      },
      {
        selector: '.step-f',
        description: `<p>Thanks to IP address connection locations,
        discover the main areas where you can reach
        each segment.</p>`,
        customDescriptionRenderer: (str, tourLogic) => (
          <div dangerouslySetInnerHTML={{ __html: str }} />
        ),
        orientationPreferences: ['west-north']
      }
    ]
  }

  menuOffset = -1

  onTourClick = () => {
    this.setState({ tourOpen: !this.state.tourOpen })
  }

  goToAdmin = () => {
    this.props.history.push('/admin/users')
  }

  goToDashboard = () => {
    this.props.history.push('/')
  }

  goToIndex = () => {
    this.props.history.push('/')
  }

  logout = refreshAuth => {
    const that = this
    return function() {
      that.doLogout(refreshAuth)
    }
  }

  doLogout = async refreshAuth => {
    await auth.logout()
    refreshAuth()
  }

  render() {
    return (
      <AuthConsumer>
        {({ userInfo = {}, isLoading, error, refreshAuth }) => {
          const admin = auth.isAdmin(userInfo || { admin: 'noop' })
          const isAdminPage = this.props.location.pathname.includes('admin')

          return (
            <div className={styles.header}>
              <Container>
                <h1 onClick={this.goToIndex}>
                  {'Automotive Market'.toUpperCase()}
                </h1>
              </Container>
              {userInfo && (
                <div style={{ position: 'sticky' }}>
                  <Menu>
                    <Container>
                      <Menu.Item>
                        <Link onClick={this.onTourClick}>Guided tour</Link>
                        {this.state.tourOpen && (
                          <Walktour
                            steps={this.state.steps}
                            options={{
                              customDescriptionRenderer: (str, tourLogic) => str
                            }}
                          />
                        )}
                      </Menu.Item>
                      {admin && !isAdminPage && (
                        <Menu.Item
                          name='admin'
                          position='right'
                          className={styles.admin}
                          onClick={this.goToAdmin}>
                          <Icon name='users' />
                          <span>{strings.header.admin}</span>
                        </Menu.Item>
                      )}
                      {isAdminPage && (
                        <Menu.Item
                          name='dashboard'
                          position='right'
                          className={styles.admin}
                          onClick={this.goToDashboard}>
                          <Icon name='dashboard' />
                          <span>{strings.header.dashboard}</span>
                        </Menu.Item>
                      )}
                      <Menu.Item
                        name='logout'
                        position={admin ? null : 'right'}
                        onClick={this.logout(refreshAuth)}
                        className={styles.logout}>
                        <Icon name='log out' />
                        <span>{strings.header.logout}</span>
                      </Menu.Item>
                    </Container>
                  </Menu>
                </div>
              )}
            </div>
          )
        }}
      </AuthConsumer>
    )
  }
}

export default withRouter(Header)
