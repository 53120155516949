import React, { PureComponent } from 'react'
import { Card, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

class DashBlock extends PureComponent {
  state = {
    isLoading: false
  }

  onLoadingChanged = isLoading => {
    this.setState({ isLoading })
  }

  render() {
    const { isLoading } = this.state
    const props = this.props
    const WrappedComponent = props.component
    const { country, brand, model, style } = props
    const wrappedProps = { country, brand, model, style }

    const clazz = {
      [styles.dashContent]: true,
      [styles.short]: !props.title
    }

    if (props.className) {
      clazz[props.className] = true
    }

    const cb = this.onLoadingChanged
    const kids = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { onLoadingChanged: cb })
    )

    return (
      <Card fluid className={styles.dashBlock}>
        {props.title && (
          <Card.Content>
            {isLoading && (
              <Loader active inline size='tiny' className={styles.loader} />
            )}
            <Card.Header textAlign='center'>{props.title}</Card.Header>
          </Card.Content>
        )}
        <Card.Content
          className={classNames(clazz)}
          style={{ height: this.props.fluid ? 'auto' : null }}>
          {WrappedComponent ? (
            <WrappedComponent
              {...wrappedProps}
              onLoadingChanged={this.onLoadingChanged}
            />
          ) : (
            kids
          )}
        </Card.Content>
      </Card>
    )
  }
}

DashBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.func,
  country: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string
}

export default DashBlock
