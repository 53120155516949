import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.css'

const Triangle = props => {
  return (
    /*background-image: linear-gradient(to right top, green 0%, green 50%, transparent 50%);*/

    <div className={classNames(styles.triangle, props.className)}>
      <div
        className={styles.left}
        style={{
          backgroundImage: `linear-gradient(to left top, ${props.color} 0%, ${props.color} 50%, transparent 50%)`
        }}></div>
      <div
        className={styles.right}
        style={{
          backgroundImage: `linear-gradient(to right top, ${props.color} 0%, ${props.color} 50%, transparent 50%)`
        }}></div>
    </div>
  )
}

export default Triangle
