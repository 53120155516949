import axios from 'axios'
import { isEmpty } from './utils'

const auth = {
  login: async function(username, password) {
    const response = await axios.post('/api/users/login', {
      username,
      password
    })

    if (response.data.status === 'success') {
      return response.data.data
    } else {
      return null
    }
  },

  logout: async function() {
    await axios.post('/api/users/logout')

    return
  },

  signup: async function(
    newfirstname,
    newlastname,
    newcompany,
    newposition,
    newemail,
    newpassword
  ) {
    const response = await axios.post('/api/users/register', {
      newfirstname,
      newlastname,
      newcompany,
      newposition,
      newemail,
      newpassword
    })
    if (response.data.status === 'success') {
      return response.data.data
    } else {
      return null
    }
  },

  isAdmin: function(userInfo) {
    let admin = false
    if (!isEmpty(userInfo) && userInfo.hasOwnProperty('admin')) {
      admin = userInfo['admin']
    } else if (userInfo.hasOwnProperty('data')) {
      admin = userInfo['data']['admin']
    }
    return admin
  }
}

export default auth
