import React, { Component } from 'react'
import numeral from 'numeral'

import AnimatedCounter from './AnimatedCounter'
import BlockLoader from '../../Components/BlockLoader'
import api from '../../api'
import strings from '../../strings'
import styles from './styles.module.css'
import Triangle from './Triangle'
import LevelButton from './LevelButton'

class Volumes extends Component {
  state = {
    isLoading: false,
    intenders: null,
    engaged: null,
    interested: null,
    highlyInterested: null
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.brand !== prevProps.brand ||
      this.props.model !== prevProps.model
    ) {
      this.fetchData()
    }
  }

  hasData = () => {
    return (
      this.state.intenders ||
      this.state.engaged ||
      this.state.interested ||
      this.state.highlyInterested
    )
  }

  async fetchData() {
    if (this.props.brand) {
      if (this.hasData()) {
        this.props.onLoadingChanged(true)
      } else {
        this.setState({ isLoading: true })
      }
      const volumes = await api.getVolumes(
        this.props.country,
        this.props.brand,
        this.props.model
      )
      this.setState({ isLoading: false, ...volumes.data.data })
      this.props.onLoadingChanged(false)
    } else {
      this.setState({
        intenders: null,
        engaged: null,
        interested: null,
        highlyInterested: null
      })
    }
  }

  onClick = level => {
    if (this.props.onLevelClicked) {
      this.props.onLevelClicked(level)
    }
  }

  render() {
    const { country, brand, model, level } = this.props
    const { intenders, engaged, interested, highlyInterested } = this.state
    let message = brand ? null : strings.dashboard.pleaseChooseBrand

    const total = intenders + engaged + interested + highlyInterested
    window.volumes = total

    return (
      <BlockLoader
        loading={this.state.isLoading}
        message={message}
        className={styles.volumes}>
        <Triangle className={styles.triangle} color={'#dedede'} />
        <div className={styles.helpMessage}>
          {strings.dashboard.interestFilterHelp}
        </div>
        <LevelButton
          color='webo red'
          label={strings.dashboard.intenders}
          number={intenders}
          country={country}
          brand={brand}
          model={model}
          level={'intenders'}
          onClick={() => this.onClick('Intenders')}
          active={!level || level === 'Intenders'}
        />
        <LevelButton
          color='webo redish'
          label={strings.dashboard.engaged}
          number={engaged}
          country={country}
          brand={brand}
          model={model}
          level={'engaged'}
          onClick={() => this.onClick('Engaged')}
          active={!level || level === 'Engaged'}
        />
        <LevelButton
          color='webo greyish'
          label={strings.dashboard.highlyInterested}
          number={highlyInterested}
          country={country}
          brand={brand}
          model={model}
          level={'highly interested'}
          onClick={() => this.onClick('Highly Interested')}
          active={!level || level === 'Highly Interested'}
        />
        <LevelButton
          color='webo grey'
          label={strings.dashboard.interested}
          number={interested}
          country={country}
          brand={brand}
          model={model}
          level={'interested'}
          onClick={() => this.onClick('Interested')}
          active={!level || level === 'Interested'}
        />
        <div className={styles.total}>
          <AnimatedCounter value={numeral(total).format()} />
        </div>
      </BlockLoader>
    )
  }
}

Volumes.defaultProps = {
  brand: null,
  model: null,
  level: null
}

export default Volumes
