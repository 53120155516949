import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router'

class UsersListItem extends PureComponent {
  render() {
    const { user } = this.props
    const { id, email, firstname, lastname, company, actif, admin } = user

    return (
      <Table.Row onClick={this.onRowClick}>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell>{lastname}</Table.Cell>
        <Table.Cell>{firstname}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>{company}</Table.Cell>
        <Table.Cell textAlign='center'>
          {actif ? <Icon name='checkmark' size='small' /> : ''}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {admin ? <Icon name='checkmark' size='small' /> : ''}
        </Table.Cell>
      </Table.Row>
    )
  }

  onRowClick = () => {
    const id = this.props.user.id
    this.props.history.push(`/admin/users/${id}`)
  }
}

UsersListItem.propTypes = {
  user: PropTypes.object.isRequired
}

export default withRouter(UsersListItem)
