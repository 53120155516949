import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    header: {
      title: 'Automotive',
      logout: 'Logout',
      admin: 'Admin',
      dashboard: 'Dashboard',
      clickToCall: 'Click to call',
      buyData: 'Buy data',
      contactDetails: 'Contact details',
      sendEmail: 'Send us an e-mail at: {0}'
    },
    signup: {
      submit: 'Submit',
      signup: 'Request Access',
      newfirstname: 'First Name',
      newlastname: 'Last Name',
      newcompany: 'Company',
      newposition: 'Position',
      newemail: 'E-mail address',
      newpassword: 'Choose a password',
      error:
        'A problem occurred when signing up. The provided email address must be unique.'
    },
    login: {
      submit: 'Connect',
      login: 'Login',
      email: 'E-mail address',
      password: 'Password',
      error: 'Invalid or unauthorized username & password'
    },
    dashboard: {
      marketVision: 'Competitive Set',
      proximity: 'Proximity Set',
      behavioral: 'Behavioral Traits',
      uplift: 'Uplift',
      volumes: 'Volumes',
      interest: 'Brand or model interest over time',
      kle: 'Life Stages',
      all: 'All',
      top: 'Top 20',
      bottom: 'Bottom 20',
      brand: 'Brand',
      model: 'Model',
      intenders: 'Intenders',
      engaged: 'Engaged',
      interested: 'Interested',
      highlyInterested: 'Highly interested',
      interestFilterHelp:
        'Click the buttons below to toggle data filtering by Interest level',
      interestTooltipModel:
        'In the {0} market, {1} unique {4} users are identified as {2} {3}.',
      interestTooltipBrand:
        'In the {0} market, {1} unique users are identified as {2} {3}.',
      marketVisionTooltip:
        'According to automobile sellers, {0} is the {2} top competitor of {1}',
      proximityTooltip:
        '{0} and {1} intenders have {3} behavioral profiles on the web. <br /> <b>Match score: {2}</b> <br /> (<i>1: low similarities, <br /> 20: exact same profiles</i>)',
      // proximityTooltip: 'According to the internet browsing patterns of Weborama’s cookies, behavioral proximities between models are established on a scale of 1 (unrelated) to 20 (closest). {0} has a proximity of {2} to {1}',
      keyLifeEventsTooltipUsPopulation:
        'In this segment there are {0}x more individuals showing a strong interest in the key life event {1} than the average of the {2} population',
      keyLifeEventsTooltipAllBrands:
        'In this segment there are {0}x more individuals showing a strong interest in the key life event {1} than the average of the car buyers population',
      behavioralTraitsTooltipUsPopulation:
        'In this segment there are {0}x more individuals showing a strong interest in the area of interest {1} than the average of the {2} population',
      behavioralTraitsTooltipAllBrands:
        'In this segment there are {0}x more individuals showing a strong interest in the area of interest {1} than the average of the car buyers population',
      interestTooltip:
        'The graph shows the evolution in unique users belonging to the segment {2} in Weborama’s database. On {0}, the cookies belonging to {2} were {1}',
      interestTooltipAll:
        'The graph shows the evolution in unique users belonging to all segments in Weborama’s database. On {0}, the cookies were {1}',
      usPopulation: 'Country Pop',
      chooseBrand: 'Brand',
      chooseModel: 'Model',
      allBrands: 'Auto Pop',
      pleaseChooseBrand: 'Please choose a Brand',
      pleaseChooseModel: 'Please choose a Model',
      pleaseChooseBrandAndModel: 'Please choose a Brand and Model',
      pleaseChooseInterestLevel: 'Please choose an Interest level'
    },
    admin: {
      users: {
        search: 'Search users',
        id: 'ID',
        lastLogin: 'Last login',
        lastName: 'Last Name',
        firstName: 'First Name',
        email: 'Email',
        emailAddress: 'Email address',
        company: 'Company',
        position: 'Position',
        active: 'Active',
        admin: 'Admin',
        password: 'Password',
        changePassword: 'Change Password',
        confirmPassword: 'Confirm Password',
        enterPasswordConfirm: 'Enter password to confirm',
        cancel: 'Cancel',
        save: 'Save',
        delete: 'Delete',
        confirmDeleteTitle: 'Do you really want to delete this user ?',
        confirmDeleteMessage:
          'This operation is irreversible, clicking the Delete button below will permanently remove the user',
        errors: {
          title: 'Could not save user',
          message: 'Please fix errors below',
          email: 'Please enter a valid email',
          firstName: 'Please enter a first name',
          lastName: 'Please enter a last name',
          password: 'Please enter a password',
          confirmPasswordEmpty: 'Please confirm the password',
          confirmPasswordDoesNotMatch:
            'Confirmed password does not match the password'
        }
      }
    }
  }
})

export default strings
