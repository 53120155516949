import React, { PureComponent } from 'react'
import axios, { CancelToken } from 'axios'
import { Pagination, Loader, Button, Input } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import queryString from 'query-string'
import memoize from 'memoize-one'

import { orderBy } from '../../utils'
import api from '../../api'
import PageBlock from '../../Components/PageBlock'
import UsersList from './UsersList'
import strings from '../../strings'
import styles from './styles.module.css'

const USERS_PER_PAGE = 20

class UsersPage extends PureComponent {
  cancel = null
  state = {
    users: [],
    loading: true
  }

  search = memoize((users, search) => {
    if (search) {
      search = search.trim()
    }
    if (!search) {
      return users
    }

    const matchBeginning = new RegExp(`^${search}`, 'i')
    const searchable = ['email', 'firstname', 'lastname', 'company']
    return users.filter(user => {
      for (let i = 0; i < searchable.length; i++) {
        const attr = searchable[i]
        if (user[attr] && user[attr].match(matchBeginning)) {
          return true
        }
      }
      return false
    })
  })

  sort = memoize((users, column, direction) => {
    if (!column) {
      return users
    }
    let dir = null
    switch (direction) {
      case 'ascending':
        dir = 'asc'
        break
      case 'descending':
        dir = 'desc'
        break
      default:
        dir = 'asc'
        break
    }
    return orderBy(users, [column], dir)
  })

  slice = memoize((users, page, num) => {
    const begin = (page - 1) * num
    const end = begin + num
    return users.slice(begin, end)
  })

  render() {
    const { page, column, direction, search } = this.params()
    const { users, loading } = this.state
    const filtered = this.search(users, search)
    const count = filtered.length

    let pages = Math.floor(count / USERS_PER_PAGE)
    const remainder = count % USERS_PER_PAGE
    if (remainder > 0) {
      pages = pages + 1
    }

    const currentPage = page > pages ? pages : page

    const sorted = this.sort(filtered, column, direction)
    const pageUsers = this.slice(sorted, currentPage, USERS_PER_PAGE)

    return (
      <PageBlock>
        <Pagination
          totalPages={pages}
          onPageChange={this.onPageChange}
          activePage={currentPage}
        />
        <Link to='/admin/users/new'>
          <Button icon='user plus' floated='right' />
        </Link>
        <Input
          className={styles.search}
          placeholder={strings.admin.users.search}
          icon='search'
          value={search || ''}
          onChange={this.onSearch}
        />
        {loading && <Loader active />}
        <UsersList
          users={pageUsers}
          sortColumn={column}
          sortDirection={direction}
          onSortClick={this.handleSort}
        />
        <Pagination
          totalPages={pages}
          onPageChange={this.onPageChange}
          activePage={currentPage}
        />
      </PageBlock>
    )
  }

  componentWillUnmount() {
    if (this.cancel) {
      this.cancel()
      this.cancel = null
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  params = () => {
    const { location } = this.props
    const query = location.search
    const params = queryString.parse(query)
    if (!params.page) {
      params.page = 1
    }

    return params
  }

  cancelToken = () => {
    const that = this
    return new CancelToken(function executor(c) {
      that.cancel = c
    })
  }

  async fetchData() {
    try {
      const data = await api.getUsers(this.cancelToken())
      const users = data.data.data
      this.setState({ users, loading: false })
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err)
      }
    }
    this.cancel = null
  }

  onPageChange = (event, data) => {
    const { activePage } = data
    const params = this.params()
    params.page = activePage
    this.changeParams(params)
  }

  handleSort = clickedColumn => () => {
    let sort = 'ascending'
    const sortParams = this.params()
    if (clickedColumn === sortParams.column) {
      sort = sortParams.direction === 'ascending' ? 'descending' : 'ascending'
    }
    const params = { ...sortParams, column: clickedColumn, direction: sort }
    this.changeParams(params)
  }

  onSearch = e => {
    const search = e.target.value
    const params = this.params()
    params.search = search
    this.changeParams(params)
  }

  changeParams = params => {
    const url = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`
    this.props.history.replace(url)
  }
}

export default withRouter(UsersPage)
